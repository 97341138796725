import React from "react"
import "../styles/press.scss"

export default function SinglePressArticle({
  note: { publishedBy, publishedByLogo = false, date, description, url },
}) {
  return (
    <article className="flex">
      {publishedByLogo ? (
        <div className="w-40 py-6 flex flex-col justify-center mb-4">
          <img
            src={publishedByLogo}
            alt={publishedBy}
            style={{ displat: "block", height: "auto", width: "100%" }}
          />
        </div>
      ) : (
        <h3 className="py-8">{publishedBy}</h3>
      )}

      <small>{date}</small>
      <p>{description}</p>
      <a target="_blank" rel="noreferrer" href={url}>
        Read more
      </a>
    </article>
  )
}
