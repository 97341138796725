import CBR from "./../images/press/cbr.svg"
import CBC from "./../images/press/cbc.svg"
import BroBible from "./../images/press/BroBible.png"
import ClevelandPlainDealer from "./../images/press/ClevelandPlainDealer.png"
import Cinemablend from "./../images/press/cinemablend.svg"
import Collider from "./../images/press/Collider.png"
import ComicBook from "./../images/press/ComicBook.png"
import ComicBookMovie from "./../images/press/ComicBookMovie.png"
import Deadline from "./../images/press/Deadline.png"
import ESPN from "./../images/press/ESPN.png"
import ESPN2 from "./../images/press/ESPN2.png"
import etcanada from "./../images/press/etcanada.png"
import HuffPost from "./../images/press/HuffPost.png"
import Mashable from "./../images/press/Mashable.png"
import Dankanator from "./../images/press/Dankanator.png"

import geektyrant from "./../images/press/geektyrant.svg"
import ew from "./../images/press/ew.svg"

import Mens from "./../images/press/Mens Health.png"
import People from "./../images/press/People.png"
import SYFYWire from "./../images/press/SYFYWire.png"
import TheMarySue from "./../images/press/themarysue.png"
import ThePlaylist from "./../images/press/theplaylist.png"
import TheWrap from "./../images/press/TheWrap.png"
import USATodayForTheWin from "./../images/press/USATodayForTheWin.png"
import iHeartRadio from "./../images/press/iHeartRadio.png"
import RepublicWorld from "./../images/press/republic_world.svg"

const data = [
  {
    url:
      "https://www.cinemablend.com/news/2557526/watch-avengers-endgames-director-trash-talk-chris-hemsworth-and-more-over-fantasy-football",
    date: "10/22/2020",
    publishedBy: "CINEMA BLEND",
    publishedByLogo: Cinemablend,
    description:
      "Watch Avengers: Endgame’s Director Trash Talk Chris Hemsworth And More Over Fantasy Football",
  },
  {
    url:
      "https://dankanator.com/81314/endgame-director-joe-russo-slams-chris-evans-onlyfans-page/",
    date: "10/20/2020",
    publishedBy: "DANKANATOR",
    publishedByLogo: Dankanator,
    description:
      "‘Endgame’ Director Joe Russo Slams Chris Evans For His OnlyFans Page?",
  },
  {
    url:
      "https://www.republicworld.com/entertainment-news/hollywood-news/joe-russo-disses-mcu-stars-but-he-has-a-reason-behind-it-watch-video.html",
    date: "10/19/2020",
    publishedBy: "REPUBLIC WORLD",
    publishedByLogo: RepublicWorld,
    description:
      "Joe Russo Disses The MCU Stars But For A Good Reason, Watch Video",
  },
  {
    url:
      "https://www.cbc.ca/comedy/simu-liu-drops-an-epic-diss-track-on-ryan-reynolds-1.5796639",
    date: "11/10/2020",
    publishedBy: "CBC",
    publishedByLogo: CBC,
    description: "Simu Liu drops an epic diss track on Ryan Reynolds",
  },
  {
    url:
      "https://www.cinemablend.com/news/2558522/ryan-reynolds-gets-ripped-on-by-shang-chis-simu-liu-in-marvel-fantasy-football-diss-track",
    date: "11/10/2020",
    publishedBy: "CINEMA BLEND",
    publishedByLogo: Cinemablend,
    description:
      "Ryan Reynolds Gets Ripped On By Shang-Chi’s Simu Liu In Marvel Fantasy Football Diss Track",
  },
  {
    url:
      "https://comicbook.com/marvel/news/shang-chi-simu-liu-ryan-reynolds-diss-track/",
    date: "11/10/2020",
    publishedBy: "COMICBOOK.COM",
    publishedByLogo: ComicBook,
    description: "Shang-Chi Star Simu Liu Releases Ryan Reynolds Diss Track",
  },
  {
    url:
      "https://www.huffingtonpost.ca/entry/simu-liu-rap-ryan-reynolds_ca_5faae611c5b67c3259b39016",
    date: "11/10/2020",
    publishedBy: "HUFFPOST CANADA",
    publishedByLogo: HuffPost,
    description:
      "Simu Liu Wrote A Diss Rap About Ryan Reynolds And It’s Perfect",
  },
  {
    url:
      "https://theplaylist.net/simu-liu-ryan-reynolds-fantasy-football-20201110/",
    date: "11/10/2020",
    publishedBy: "THE PLAYLIST",
    publishedByLogo: ThePlaylist,
    description:
      "‘Shang-Chi’ Star Simu Liu Targets Ryan Reynolds & ‘RIPD’ In A Diss Track For Russo Brothers’ Fantasy Football League",
  },

  {
    url:
      "https://comicbook.com/movies/news/joe-russo-trash-talks-mcu-stars-chris-evans-onlyfans-page/",
    date: "10/18/2020",
    publishedBy: "COMICBOOK.COM",
    publishedByLogo: ComicBook,
    description:
      "Avengers: Endgame Director Slams Captain America Actor Chris Evans Over His OnlyFans Page",
  },
  {
    url: "https://ew.com/movies/joe-russo-marvel-trash-talk/",
    date: "10/17/2020",
    publishedBy: "ENTERTAINMENT WEEKLY",
    publishedByLogo: ew,
    description:
      "Watch Joe Russo brutally trash talk every MCU star — for a good cause",
  },
  {
    url:
      "https://geektyrant.com/news/paul-rudd-sings-iron-man-to-trash-talk-robert-downy-jr-in-agbo-charity-fantasy-football-league",
    date: "10/7/2020",
    publishedBy: "GEEK TYRANT",
    publishedByLogo: geektyrant,
    description:
      'Paul Rudd Sings "Iron Man" to Trash Talk Robert Downey Jr. in AGBO Charity Fantasy Football League',
  },
  {
    url:
      "https://etcanada.com/news/699248/paul-rudd-responds-to-robert-downey-jrs-fantasy-football-taunt-with-a-black-sabbath-parody/",
    date: "10/6/2020",
    publishedBy: "ET CANADA",
    publishedByLogo: etcanada,
    description:
      "Paul Rudd Responds To Robert Downey Jr’s Fantasy Football Taunt With A Black Sabbath Parody",
  },
  {
    url:
      "https://comicbook.com/marvel/news/paul-rudd-bites-off-iron-mans-head-trash-talks-robert-downey-jr/",
    date: "10/6/2020",
    publishedBy: "COMICBOOK.COM",
    publishedByLogo: ComicBook,
    description:
      "Paul Rudd Bites Off Iron Man's Head, Trash Talks Robert Downey Jr. In Rocking Video",
  },
  {
    url:
      "https://www.cbr.com/ant-man-paul-rudd-slams-iron-man-fantasy-football-video/",
    date: "10/6/2020",
    publishedBy: "CBR.COM",
    publishedByLogo: CBR,
    description:
      "Ant-Man's Paul Rudd Slams Iron Man's RDJ in Fantasy Football Trash Talk Video",
  },
  {
    url: "https://www.themarysue.com/paul-rudd-vs-rdj/",
    date: "10/5/2020",
    publishedBy: "THE MARY SUE",
    publishedByLogo: TheMarySue,
    description:
      "Paul Rudd and Robert Downey Jr. Roasting Each Other Over Charity Fantasy Football Is Delightful",
  },
  {
    url:
      "https://ftw.usatoday.com/2020/10/paul-rudd-robert-downey-jr-fantasy-football-video",
    date: "10/5/2020",
    publishedBy: "USA TODAY: FOR THE WIN!",
    publishedByLogo: USATodayForTheWin,
    description:
      "Paul Rudd parodies Black Sabbath's 'Iron Man' in epic fantasy football trash talk video",
  },
  {
    url:
      "https://comicbook.com/irl/news/robert-downey-jr-paul-rudd-superhero-fantasy-football-league-video/",
    date: "10/2/2020",
    publishedBy: "COMICBOOK.COM",
    publishedByLogo: ComicBook,
    description:
      "Robert Downey Jr. Talks To Animated Paul Rudd In Superhero Fantasy Football League Video",
  },
  {
    url:
      "https://comicbook.com/marvel/news/robert-downey-jr-paul-rudd-animated-cry/",
    date: "10/4/2020",
    publishedBy: "COMICBOOK.COM",
    publishedByLogo: ComicBook,
    description:
      "Robert Downey Jr. Makes Animated Paul Rudd Cry, Threatens To Buy His Candy Store And Eat All The Candy",
  },
  {
    url:
      "https://theplaylist.net/paul-rudd-robert-downey-jr-fantasy-football-feud-20201005/",
    date: "10/5/2020",
    publishedBy: "THE PLAYLIST",
    publishedByLogo: ThePlaylist,
    description:
      "The Paul Rudd Vs. Robert Downey Jr. Fantasy Football Feud Is The Silly Distraction We Need Right Now",
  },
  {
    url:
      "https://www.joblo.com/movie-news/paul-rudd-takes-trash-talk-to-the-next-level-in-fantasy-football-music-video",
    date: "10/5/2020",
    publishedBy: "JOBLO",
    publishedByLogo: null,
    description:
      "Paul Rudd Takes Trash Talk To The Next Level In Fantasy Football Music Video",
  },
  {
    url:
      "https://people.com/sports/paul-rudd-robert-downey-jr-fantasty-football-trash-tralk-video/",
    date: "10/5/2020",
    publishedBy: "PEOPLE",
    publishedByLogo: People,
    description:
      "Paul Rudd Tells Robert Downey Jr. He's 'Going to Lose to an Ant' in Hilarious Fantasy Football Video",
  },

  {
    url:
      "https://www.cinemablend.com/news/2554551/robert-downey-jr-and-his-fantasy-football-league-are-dedicating-a-ton-of-money-in-chadwick-bosemans-memory",
    date: "9/11/2020",
    publishedBy: "CINEMABLEND",
    publishedByLogo: Cinemablend,
    description:
      "Robert Downey Jr And His Fantasy Football League Are Dedicating A Ton Of Money In Chadwick Boseman's Memory",
  },
  {
    url:
      "https://ftw.usatoday.com/2020/09/the-mcu-actors-are-all-in-a-fantasy-football-league-and-here-are-their-top-picks",
    date: "9/10/2020",
    publishedBy: "USA TODAY: FOR THE WIN",
    publishedByLogo: USATodayForTheWin,
    description:
      "The MCU actors are all in a fantasy football league and here are their top picks",
  },
  {
    url:
      "https://www.thewrap.com/russo-bros-agbo-fantasy-football-league-to-donate-250000-in-chadwick-bosemans-name-to-charity/",
    date: "9/4/2020",
    publishedBy: "THE WRAP",
    publishedByLogo: TheWrap,
    description:
      "Russo Bros’ AGBO Fantasy Football League to Donate $250,000 in Chadwick Boseman’s Name to Charity",
  },
  {
    url:
      "https://comicbook.com/movies/news/robert-downey-jr-agbo-fantasy-football-league-2020/",
    date: "9/4/2020",
    publishedBy: "COMICBOOK.COM",
    publishedByLogo: ComicBook,
    description: "Robert Downey Jr Kicks Off AGBO Fantasy Football League 2020",
  },
  {
    url:
      "https://comicbook.com/marvel/news/marvel-agbo-fantasy-football-league-avengers-season-2/",
    date: "9/4/2020",
    publishedBy: "COMICBOOK.COM",
    publishedByLogo: ComicBook,
    description:
      "Marvel Stars Return for Season 2 of AGBO Superhero Fantasy Football League",
  },
  {
    url:
      "https://www.cleveland.com/entertainment/2020/09/avengers-endgame-director-cleveland-native-joe-russos-marvel-fantasy-football-league-to-raise-125m-for-charity.html",
    date: "9/4/2020",
    publishedBy: "CLEVELAND PLAIN DEALER",
    publishedByLogo: ClevelandPlainDealer,
    description:
      "’Avengers: Endgame’ director, Cleveland native Joe Russo’s Marvel fantasy football league to raise $1.25M for charity",
  },
  {
    url:
      "https://brobible.com/culture/article/the-avengers-are-once-again-teaming-up-for-a-superhero-fantasy-football-charity-league/",
    date: "9/4/2020",
    publishedBy: "BROBIBLE",
    publishedByLogo: BroBible,
    description:
      "The Avengers Are Once Again Teaming Up For A Superhero Fantasy Football Charity League",
  },
  {
    url:
      "https://www.themarysue.com/exclusive-karen-gillan-vs-ryan-reynolds-part-two/",
    date: "12/8/2019",
    publishedBy: "THE MARY SUE",
    description:
      "Exclusive: Karen Gillan vs Ryan Reynolds Part Two Starts With Gillan’s Fighting Words,”",
  },
  {
    url:
      "https://comicbook.com/marvel/2019/12/02/joe-russo-matthew-berry-agbo-superhero-league-avengers-fantasy-football/",
    date: "12/2/2019",
    publishedBy: "COMICBOOK.COM",
    publishedByLogo: ComicBook,
    description:
      "Avengers Director Joe Russo and ESPN's Matthew Berry on the AGBO Superhero Fantasy Football League,”",
  },
  {
    url:
      "https://ftw.usatoday.com/2019/11/agbo-superhero-league-fantasy-football",
    date: "11/27/2019",
    publishedBy: "USA TODAY: FOR THE WIN",
    publishedByLogo: USATodayForTheWin,
    description:
      "Take a peek inside Hollywood's most exclusive fantasy football leagues,”",
  },
  {
    url:
      "https://www.thewrap.com/paul-rudd-levels-chris-pratt-superhero-fantasy-football-trash-talk-video/",
    date: "11/24/2019",
    publishedBy: "THE WRAP",
    publishedByLogo: TheWrap,
    description:
      "Paul Rudd Levels Chris Pratt With Superhero Fantasy Football Trash Talk (Video),”",
  },
  {
    url:
      "https://ftw.usatoday.com/2019/11/paul-rudd-trash-talks-chris-pratt-in-fantasy-football",
    date: "11/24/2019",
    publishedBy: "USA TODAY: FOR THE WIN",
    publishedByLogo: USATodayForTheWin,
    description:
      "Paul Rudd trash talks Chris Pratt into the ground (in fantasy football),”",
  },
  {
    url:
      "https://app.criticalmention.com/app/#/clip/public/88608ab1-4351-446b-b572-8e0c298ed97a",
    date: "11/24/2019",
    publishedBy: "ESPN 2: FANTASY FOOTBALL NOW",
    publishedByLogo: ESPN2,
    description: "Chris Pratt Smack Talk Video coverage,”",
  },
  {
    url:
      "https://deadline.com/2019/11/chris-pratt-paul-rudd-agbo-fantasy-football-1202794174/",
    date: "11/24/2019",
    publishedBy: "DEADLINE",
    publishedByLogo: Deadline,
    description:
      "Chris Pratt And Paul Rudd Search For The Endzone Instead Of The ‘Endgame’ In Trash-Talking Vid,”",
  },
  {
    url:
      "https://www.comicbookmovie.com/avengers/avengers_endgame/paul-rudd-trash-talks-avengers-endgame-costar-chris-pratt-prior-to-their-fantasy-football-showdown-a171833",
    date: "11/24/2019",
    publishedBy: "COMIC BOOK MOVIE",
    publishedByLogo: ComicBookMovie,
    description:
      "Paul Rudd Trash Talks AVENGERS: ENDGAME Costar Chris Pratt Prior To Their Fantasy Football Showdown,”",
  },
  {
    url:
      "https://comicbook.com/marvel/2019/11/24/chris-pratt-paul-rudd-says-sucks-new-video-russo-brothers-fantasy-football-league/",
    date: "11/24/2019",
    publishedBy: "COMICBOOK.COM",
    publishedByLogo: ComicBook,
    description:
      "Chris Pratt Says Paul Rudd Sucks” in New Video for the Russo Brothers’ Fantasy Football League,”",
  },
  {
    url:
      "https://people.com/movies/ryan-reynolds-eats-robert-downey-jr-superhero-fantasy-football/",
    date: "11/1/2019",
    publishedBy: "PEOPLE",
    publishedByLogo: People,
    description:
      "Ryan Reynolds 'Eats' Robert Downey Jr. in Silly Superhero Fantasy Football League Challenge,”",
  },
  {
    url:
      "https://mashable.com/article/ryan-reynolds-robert-downey-jr-fantasy-football/",
    date: "11/1/2019",
    publishedBy: "MASHABLE",
    publishedByLogo: Mashable,
    description:
      "Ryan Reynolds taunts Robert Downey Jr. over fantasy football with a glorious video,”",
  },
  {
    url:
      "https://www.themarysue.com/robert-downey-jr-ryan-reynolds-football-league/",
    date: "11/1/2019",
    publishedBy: "MARY SUE",
    description:
      "I Can’t Get Enough of Ryan Reynolds and Robert Downey Jr.’s Superhero Charity Fantasy Football League Trash Talk,”",
  },
  {
    url:
      "https://www.huffpost.com/entry/ryan-reynolds-robert-downey-jr-football-videos_n_5dbc0c63e4b09d8f97999fea",
    date: "11/1/2019",
    publishedBy: "HUFFPOST",
    publishedByLogo: HuffPost,
    description:
      "Ryan Reynolds Has Tastiest Response To Robert Downey Jr.’s ‘Eat Me’ Taunt,”",
  },
  {
    url:
      "https://ftw.usatoday.com/2019/09/avengers-actors-fantasy-football-league-together-and-here-are-their-top-picks",
    date: "10/13/2019",
    publishedBy: "USA TODAY: FOR THE WIN",
    publishedByLogo: USATodayForTheWin,
    description:
      "The 'Avengers' actors are in a fantasy football league and here are their top picks,",
  },
  {
    url:
      "https://comicbook.com/marvel/2019/10/11/avengers-endgame-fantasy-football-tom-holland-joe-russo-secret-handshake/",
    date: "10/11/2019",
    publishedBy: "COMICBOOK.COM",
    publishedByLogo: ComicBook,
    description:
      "Avengers: Endgame Director Joe Russo and Tom Holland Reveal the Secret Chris Handshake,”",
  },
  {
    url: "https://www.syfy.com/syfywire/nebula-vs-deadpool-fantasy-football",
    date: "9/27/2019",
    publishedBy: "SYFY WIRE",
    publishedByLogo: SYFYWire,
    description:
      "Nebula Vs Deadpool! Karen Gillan And Ryan Reynolds Spar Over Fantasy Football,”",
  },
  {
    url:
      "https://www.iheart.com/content/2019-09-27-ryan-reynolds-trash-talks-karen-gillan/",
    date: "9/27/2019",
    publishedBy: "IHEART RADIO",
    publishedByLogo: iHeartRadio,
    description: "Ryan Reynolds Trash Talks Karen Gillan,",
  },
  {
    url:
      "https://www.huffingtonpost.ca/entry/ryan-reynolds-trash-talk-karen-gillan_ca_5d8d3485e4b0019647a5d415",
    date: "9/26/2019",
    publishedBy: "HUFFPOST",
    publishedByLogo: HuffPost,
    description:
      "Ryan Reynolds Trash Talks Chris Evans, Karen Gillan ‘Like A Canadian’ In New Video,",
  },
  {
    url:
      "https://collider.com/ryan-reynolds-karen-gillan-fantasy-football-twitter-videos/",
    date: "9/26/2019",
    publishedBy: "COLLIDER",
    publishedByLogo: Collider,
    description:
      "Watch Ryan Reynolds and Karen Gillan Talk Serious Fantasy Football Trash for Charity,",
  },
  {
    url:
      "https://brobible.com/culture/article/ryan-reynolds-karen-gillan-fantasy-football/",
    date: "9/24/2019",
    publishedBy: "BROBIBLE",
    publishedByLogo: BroBible,
    description:
      "Marvel Stars Ryan Reynolds And Karen Gillan Went HAM On Each Other In Some Seriously Funny Fantasy Football Trash Talk,",
  },
  {
    url:
      "https://www.menshealth.com/entertainment/a29036437/literally-all-your-favorite-mcu-heroes-are-playing-in-this-charity-fantasy-football-league/",
    date: "9/13/2019",
    publishedBy: "MEN'S HEALTH",
    publishedByLogo: Mens,
    description:
      "Literally All Your Favorite MCU Heroes Are Playing In This Charity Fantasy Football League,”",
  },
  {
    url:
      "https://www.espn.com/fantasy/football/story/_/page/TMRlovehate190912/matthew-berry-love-hate-fantasy-football-picks-sleepers-busts-week-2",
    date: "9/12/2019",
    publishedBy: "ESPN",
    publishedByLogo: ESPN,
    description:
      "Love, Hate and An Extraordinary League of Ladies and Gentlemen”",
  },
]

export default data
